import React, { Component, Suspense } from "react";
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./scss/style.scss";
import ForgotPassword from "../src/views/pages/forgot-password/ForgotPassword";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  checkAuthentication = () => {
    return true
  };

  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="*"
              element={
                this.checkAuthentication() ? (
                  <DefaultLayout />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route exact path="/forgot-password" name="Forgot Password" element={<ForgotPassword />} />
            <Route 
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
