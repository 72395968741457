// ./src/views/pages/forgot-password/ForgotPassword.js
import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CRow,
} from "@coreui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "src/helper/services";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Use navigate for navigation

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const response = await axios.post(`${BASE_URL}/user/forgotpassword`, {
        email,
      });
      setMessage(response.data.message);
    } catch (error) {
      setError("Error sending email");
    } finally {
      setLoading(false);
    }
  };

  const handleBackToLogin = () => {
    navigate("/"); // Navigate to the login page
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>Forgot Password</h1>
                    <p className="text-medium-emphasis">
                      Enter your email to reset your password
                    </p>
                    <CRow className="mb-3">
                      <CCol md={12}>
                        <CFormInput
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </CCol>
                    </CRow>
                    {error && <p className="text-danger">{error}</p>}
                    {message && <p className="text-success">{message}</p>}
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          type="submit"
                          color="primary"
                          className="px-4"
                          disabled={loading}
                        >
                          {loading ? "Sending..." : "Send Email"}
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton
                          color="link"
                          className="px-0"
                          onClick={handleBackToLogin}
                        >
                          Back to Login
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;
